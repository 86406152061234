import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BodyOverlay from "../components/BodyOverlay";
import BlogCard from "../components/BlogCard";
import { client } from "../client";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const blogsquery = `*[_type == "post"]{
      ...,
      "name": author->name,
      "authorImg" : author->image,
      "cat": categories->title
    }`;

    client.fetch(blogsquery).then((data) => {
      setBlogs(data);
    });
  }, []);

  // console.log(blogs)
  return (
    <>
      <BodyOverlay />

      <Header />
      {/* breadcrumbs area start */}
      <div
        className="breadcrumbs_aree breadcrumbs_bg mb-100"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Blogs</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog page section start */}
      <div className="blog_page_section mb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog_page_sidebar">
                <div className="blog_page_inner">
                  <div className="row">
                    {blogs?.map((blog) => {
                      return <BlogCard blog={blog} key={blog._id}/>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog page section end */}
      <Footer />
    </>
  );
};

export default Blogs;
