import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client, urlFor } from "../client";

const Category = () => {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const query = `*[_type == "categoryProduct"][0..3]{
      ...,
      "route": route->title
    }`;

    client.fetch(query).then((data) => {
      setCategory(data);
    });
  }, [category]);

  console.log(category)

  return (
    <>
      <div className="service_section mb-86">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="services_section_inner d-flex justify-content-between">
                {category?.map((categoryItem) => {
                  return (
                    <div
                      className="single_services one text-center wow fadeInUp"
                      data-wow-delay="0.1s"
                      data-wow-duration="1.1s"
                    >
                      <Link to={"/catproduct/" + categoryItem.route}>
                        <div className="services_thumb">
                          <img
                            src={urlFor(categoryItem?.image)}
                            alt={categoryItem.title}
                            className="category-image"
                          />
                        </div>
                        <div className="services_content">
                          <h4>
                            <a href="#">{categoryItem.title} </a>
                          </h4>
                          <p>{categoryItem.description} </p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
