import React, { useState, useEffect } from "react";
import { client, urlFor } from "../client";
import BodyOverlay from "../components/BodyOverlay";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import BlockContent from "@sanity/block-content-to-react";

const SingleProduct = () => {
  const location = useLocation();
  const productId = location.pathname.split("/")[2];

  const [products, setProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [contact, setContact] = useState([]);
  const [category, setCategory] = useState([]);

  const [selectedVariant, setSelectedVariant] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [variants, setVariants] = useState([]);

  const handleVariantSelect = (e) => {
    const selectedIndex = e.target.value;
    setSelectedVariant(variants[selectedIndex]);
    setTotalPrice(variants[selectedIndex].price);
  };

  useEffect(() => {
    const query = `*[_type == "product"][_id == '${productId}']`;
    const Productsquery = `*[_type == "product"]`;
    const WhatsappNoQuery = `*[_type == "settings"][_id == '2b1e55d8-cfd2-499f-899f-cc14fa7eacc7']`;
    const VariantQuery = `*[_type == "product"][_id == '${productId}']`;
    const Categoryquery = '*[_type == "productCategory"]';

    client.fetch(Categoryquery).then((data) => {
      setCategory(data);
    });

    client.fetch(VariantQuery).then((data) => {
      setVariants(data[0].variants);
    });

    client.fetch(WhatsappNoQuery).then((data) => {
      setContact(data[0]);
    });

    client.fetch(query).then((data) => {
      setProducts(data);
    });

    client.fetch(Productsquery).then((data) => {
      setRelatedProducts(data);
    });
  }, [relatedProducts]);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 500, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      <BodyOverlay />
      <Header />
      {/* breadcrumbs area start */}
      <div className="breadcrumbs_aree breadcrumbs_bg mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Product</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumbs area end */}
      {/* single product section start*/}
      <div className="single_product_section mb-100">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-12 d-flex align-items-center px-2 py-3 flex-wrap justify-content-center">
              <Link to="/shop" className="d-none border-0 d-sm-inline-block shadow-sm mx-2 mb-2 btn-order-cart">
                All
              </Link>
              {category?.map((catItem, key) => {
                return (
                  <Link
                    key={key}
                    to={"/catproduct/" + catItem.title}
                    className="d-none border-0 d-sm-inline-block shadow-sm mx-2 mb-2 btn-order-cart"
                  >
                    {catItem.title}
                  </Link>
                );
              })}
            </div>
          </div>
          {products?.map((product) => {
            return (
              <div className="row" key={product._id}>
                <div className="col-lg-6 col-md-6">
                  <div className="single_product_gallery">
                    <div className="product_gallery_inner d-flex">
                      <div className="product_gallery_main_img">
                        <div
                          className="gallery_img_list"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Slide>
                            <img
                              src={urlFor(product?.image)}
                              alt={product.title}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <img
                              src={
                                product.image1
                                  ? urlFor(product?.image1)
                                  : "../assets/img/others/no-img.jpg"
                              }
                              alt={product.title}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <img
                              src={
                                product.image2
                                  ? urlFor(product?.image2)
                                  : "../assets/img/others/no-img.jpg"
                              }
                              alt={product.title}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="product_details_sidebar">
                    <h2 className="product__title">{product.title}</h2>
                    <div className="quickview__info mb-0">
                      <p className="product_review d-flex align-items-center">
                        <span className="review_icon d-flex">
                          {[...Array(product.reviews_star)]?.map(
                            (elementInArray, index) => (
                              <i className="ion-ios-star" key={index} />
                            )
                          )}
                        </span>
                        <span className="review__text">
                          ({product.reviews_count} reviews)
                        </span>
                      </p>
                      {product.weight ? (
                        <p className="product_review d-flex align-items-center">
                          Weight : {product.weight}
                        </p>
                      ) : (
                        ""
                      )}
                      {product.size ? (
                        <p className="product_review d-flex align-items-center">
                          Size : {product.size}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="product_details_desc">
                      <BlockContent
                          blocks={product.description}
                          projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                          dataset="production"
                        />
                    </div>
                    
                    <div>
                      <select
                        onChange={handleVariantSelect}
                        className="form-select mb-3 variant-select"
                      >
                        <option selected disabled>
                          Select an option
                        </option>
                        {variants?.map((variant, index) => (
                          <option key={variant._id} value={index}>
                            {variant.quantity} -{" "}
                            {variant.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "LKR",
                            })}
                          </option>
                        ))}
                      </select>
                      {totalPrice ? (
                        <div className="price_box">
                          <span className="current_price">Rs.{totalPrice}</span>
                        </div>
                      ) : (
                        <div className="price_box">
                          <span className="current_price">
                            {product.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "LKR",
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="product_pro_button quantity d-flex align-items-center">
                      <a
                        aria-label="Chat on WhatsApp"
                        href={`https://wa.me/${contact.phone1}?text=I'm%20interested%20in%20your%20${product.title}`}
                        className="add_to_cart OrderInWhatsapp"
                        target="_blank"
                      >
                        <img
                          alt="Chat on WhatsApp"
                          src="../assets/img/others/WhatsAppButtonGreenSmall.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* product details section end*/}

      {/* product tab section start */}
      {/* <div className="product_tab_section mb-80" style={{ display: "none" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="product_tab_navigation">
                <ul className="nav justify-content-center">
                  <li>
                    <a
                      className="active"
                      data-bs-toggle="tab"
                      href="#description"
                      aria-controls="description"
                    >
                      Description
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tab"
                      href="#aditional"
                      aria-controls="aditional"
                    >
                      information
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tab"
                      href="#reviews"
                      aria-controls="reviews"
                    >
                      Reviews 03
                    </a>
                  </li>
                </ul>
              </div>
              <div className="product_page_content_inner tab-content">
                <div
                  className="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                >
                  <div className="product_tab_desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum
                    </p>
                  </div>
                </div>
                <div className="tab-pane fade" id="aditional" role="tabpanel">
                  <div className="product_d_information">
                    <form action="#">
                      <table className="border-top">
                        <tbody>
                          <tr>
                            <td className="first_child">Compositions</td>
                            <td>Polyester</td>
                          </tr>
                          <tr>
                            <td className="first_child">Styles</td>
                            <td>Girly</td>
                          </tr>
                          <tr>
                            <td className="first_child">Properties</td>
                            <td>Short Dress</td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="product_info_desc">
                    <p className="mb-0">
                      Fashion has been creating well-designed collections since
                      2010. The brand offers feminine designs delivering stylish
                      separates and statement dresses which have since evolved
                      into a full ready-to-wear collection in which every item
                      is a vital part of a woman's wardrobe. The result? Cool,
                      easy, chic looks with youthful elegance and unmistakable
                      signature style. All the beautiful pieces are made in
                      Italy and manufactured with the greatest attention. Now
                      Fashion extends to a range of accessories including shoes,
                      hats, belts and more!
                    </p>
                  </div>
                </div>
                <div className="tab-pane fade" id="reviews" role="tabpanel">
                  <div className="reviews__wrapper">
                    <h2>Write Your Own Review</h2>
                    <div className="customer__reviews d-flex justify-content-between mb-20">
                      <div className="customer_reviews_left">
                        <div className="comment__title">
                          <h2>Add a review </h2>
                          <p>
                            Your email address will not be published. Required
                            fields are marked
                          </p>
                        </div>
                        <div className="reviews__ratting">
                          <h3>Your rating</h3>
                          <ul className="d-flex">
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="customer_reviews_right">
                        <h2 className="reviews__title">Customer Reviews</h2>
                        <div className="reviews__ratting">
                          <ul className="d-flex">
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-ios-star" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="reviews__desc">
                          <h3>Lorem ipsum dolor sit amet</h3>
                          <span>Deate on July 22, 2020</span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="product_review_form">
                      <form action="#">
                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="review_comment">Your review </label>
                            <textarea
                              className="border"
                              name="comment"
                              id="review_comment"
                              defaultValue={""}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label htmlFor="author">Name</label>
                            <input className="border" id="author" type="text" />
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label htmlFor="email">Email </label>
                            <input className="border" id="email" type="text" />
                          </div>
                        </div>
                        <button
                          className="btn custom-btn text-white"
                          data-hover="Submit"
                          type="submit"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* product tab section end */}
      {/* product section start */}
      <div className="product_section mb-80">
        <div className="container">
          <div className="section_title text-center mb-55">
            <h2>Related Products</h2>
            {/* <p>
              The Perfect Addition: Top-Rated Related Products for Your Needs
            </p> */}
          </div>
          <Slider
            {...settings}
            className="row product_slick slick_navigation slick__activation"
          >
            {relatedProducts?.map((product) => {
              return (
                <div className="col-lg-3" key={product._id}>
                  <article className="single_product">
                    <figure>
                      <div className="product_thumb">
                        <Link to={`/shop/${product._id}`}>
                          <img
                            src={urlFor(product?.image)}
                            alt={product.title}
                          />
                        </Link>
                      </div>
                      <figcaption className="product_content text-center">
                        <h4>
                          <a href="#">{product.title}</a>
                        </h4>
                        <div className="price_box">
                          <span className="current_price">
                            {product.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "LKR",
                            })}
                          </span>
                        </div>
                      </figcaption>
                    </figure>
                  </article>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      {/* product section end */}
      <Footer />
    </>
  );
};

export default SingleProduct;
