import React from "react";
import moment from "moment";
import { urlFor } from "../client";
import { Link } from "react-router-dom";


const BlogCard = ({ blog }) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6 home-blog-card">
        <div
          className="single_blog wow fadeInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.1s"
        >
          <div className="blog_thumb">
            <Link to={"/blog/"+blog._id}>
              <img src={urlFor(blog?.mainImage)} alt= {blog.title} style={{height: "270px", objectFit: "cover"}}/>
            </Link>
          </div>
          <div className="blog_content">
            <div className="blog_arrow_btn">
              <Link to={"/blog/"+blog._id}>
                <i className="ion-arrow-right-c" />
              </Link>
            </div>
            <span>{blog.cat}</span>
            <h3>
              <Link to={"/blog/"+blog._id}>
                {blog.title}
              </Link>
            </h3>
            <div className="blog__meta d-flex align-items-center">
              <div className="blog__meta__thumb">
                <img src={blog.authorImg ? urlFor(blog?.authorImg) : "../assets/img/others/no-user.png"} alt={blog.name} style={{width: "40px", height: "40px", borderRadius: "50%"}}/>
              </div>
              <div className="blog__meta__text">
                <ul className="d-flex">
                  <li>{blog.name}</li>
                  <li>
                    <i className="icofont-calendar" /> {moment(blog.publishedAt).utc().format('YYYY-MM-DD')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
