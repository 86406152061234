import React from "react";

const BodyOverlay = () => {
  return (
    <>
      <div className="body_overlay"></div>
    </>
  );
};

export default BodyOverlay;
