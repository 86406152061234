import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client, urlFor } from "../client";
import Featured from "../components/Featured";
import Footer from "../components/Footer";
import Header from "./../components/Header";
import Slider from "react-slick";
import Category from "../components/Category";
import BodyOverlay from "../components/BodyOverlay";
import Product from "../components/Product";
import BlogCard from "./../components/BlogCard";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [bestProducts, setBestProducts] = useState([]);
  const [hero, setHero] = useState([]);
  const [banner1, setBanner1] = useState([]);
  const [banner2, setBanner2] = useState([]);
  const [banner3, setBanner3] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const Productsquery = '*[_type == "product"][0..7]';
    const BestProductsquery = `*[_type == "product"][bestSeller == true]`;
    const Heroquery = `*[_type == "banner"][_id == 'fb76cfff-1bd6-4353-af2b-8f5dda7c3550']`;
    const Banner1query = `*[_type == "banner"][_id == 'ac3c68b6-128e-4b94-933c-c63ba57ecf2a']`;
    const Banner2query = `*[_type == "banner"][_id == '66e3624d-3e9e-4485-bbc0-b8b2956cf99d']`;
    const Banner3query = `*[_type == "banner"][_id == '32e62d04-3867-480f-97f2-1b32f226d95c']`;
    const blogsquery = `*[_type == "post"]{
      ...,
      "name": author->name,
      "authorImg" : author->image,
      "cat": categories->title
    }`;

    client.fetch(Productsquery).then((data) => {
      setProducts(data);
    });

    client.fetch(BestProductsquery).then((data) => {
      setBestProducts(data);
    });

    client.fetch(Heroquery).then((data) => {
      setHero(data);
    });

    client.fetch(Banner1query).then((data) => {
      setBanner1(data);
    });

    client.fetch(Banner2query).then((data) => {
      setBanner2(data);
    });

    client.fetch(Banner3query).then((data) => {
      setBanner3(data);
    });

    client.fetch(blogsquery).then((data) => {
      setBlogs(data);
    });
  }, [blogs, products]);

  // console.log(products.length);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 500, settings: { slidesToShow: 1 } },
    ],
  };

  const blog = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: false,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 576, settings: { slidesToShow: 1 } },
    ],
  };
  return (
    <>
      <BodyOverlay />
      <Header />
      {/*slide banner section start*/}
      <div className="hero_banner_section d-flex align-items-center mb-110">
        <div className="container">
          <div className="hero_banner_inner">
            {hero?.map((hero) => {
              return (
                <div className="row align-items-center" key={hero._id}>
                  <div className="col-lg-5">
                    <div className="hero_content">
                      {hero.sale ? (
                        <h3
                          className="wow fadeInUp"
                          data-wow-delay="0.1s"
                          data-wow-duration="1.1s"
                        >
                          <span>{hero.sale}% </span>
                          Sale Off
                        </h3>
                      ) : (
                        ""
                      )}

                      <h1
                        className="wow fadeInUp"
                        data-wow-delay="0.2s"
                        data-wow-duration="1.2s"
                      >
                        {hero.title}
                      </h1>
                      <Link
                        className="btn btn-link wow fadeInUp"
                        data-wow-delay="0.3s"
                        data-wow-duration="1.3s"
                        to="/shop"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="hero_shape_banner">
                      <img
                        className="banner_keyframes_animation wow"
                        src={urlFor(hero?.image)}
                        alt={hero.title}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="hero_mini_shape shape1">
          <img src="../assets/img/others/h6.png" alt="" />
        </div>
        <div className="hero_mini_shape shape2">
          <img src="../assets/img/others/h4.png" alt="" />
        </div>
        <div className="hero_mini_shape shape3">
          <img src="../assets/img/others/h1.png" alt="" />
        </div>
        <div className="hero_mini_shape shape4">
          <img src="../assets/img/others/h2.png" alt="" />
        </div>
        <div className="hero_mini_shape shape5">
          <img src="../assets/img/others/h5.png" alt="" />
        </div>
      </div>
      {/*slider area end*/}
      {/* service section start*/}
      <Category />
      {/* service section end*/}
      {/* banner section  start */}
      <div className="banner_section mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="single_banner wow fadeInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1.1s"
              >
                {banner1?.map((banner) => {
                  return (
                    <div className="banner_thumb" key={banner._id}>
                      <a href="#">
                        <img src={urlFor(banner?.image)} alt="" />
                      </a>
                      <div className="banner_text">
                        {banner.sale ? (
                          <h3>
                            <span>{banner.sale}%</span> Sale Off
                          </h3>
                        ) : (
                          ""
                        )}

                        <h2>{banner.title}</h2>
                        <Link className="btn btn-link" to="/shop">
                          Explore
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="single_banner wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.2s"
              >
                {banner2?.map((banner) => {
                  return (
                    <div className="banner_thumb" key={banner._id}>
                      <a href="#">
                        <img src={urlFor(banner?.image)} alt={banner.title} />
                      </a>
                      <div className="banner_text">
                        {banner.sale ? (
                          <h3>
                            <span>{banner.sale}%</span> Sale Off
                          </h3>
                        ) : (
                          ""
                        )}
                        <h2>{banner.title}</h2>
                        <Link className="btn btn-link" to="/shop">
                          Explore
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner section  end */}
      {/* product section start */}
      <div
        className="product_section mb-80 wow fadeInUp"
        data-wow-delay="0.1s"
        data-wow-duration="1.1s"
      >
        <div className="container">
          <div className="product_header">
            <div className="section_title text-center">
              <h2>New Products</h2>
              <p>
                Satisfying Cravings with Next-Level Desserts: A Guide to New
                Cake, Chocolate, and Brownie Products
              </p>
            </div>
          </div>
          <div className="tab-content product_container">
            <div
              className="tab-pane fade show active"
              id="features"
              role="tabpanel"
            >
              <div className="product_gallery">
                <div className="row">
                  {products?.map((product) => {
                    return <Product product={product} key={product._id} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner fullwidth section start */}
      {banner3?.map((banner) => {
        return (
          <div
            className="banner_fullwidth_section mb-105 "
            style={{ backgroundImage: `url(${urlFor(banner?.image)})` }}
            key={banner._id}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner_discount_text text-center">
                    {banner.sale ? (
                      <h3
                        className="wow fadeInUp"
                        data-wow-delay="0.1s"
                        data-wow-duration="1.1s"
                      >
                        <span>{banner.sale}% </span> Sale Off
                      </h3>
                    ) : (
                      ""
                    )}

                    <h2
                      className="wow fadeInUp"
                      data-wow-delay="0.2s"
                      data-wow-duration="1.2s"
                    >
                      {banner.title}
                    </h2>
                    <p
                      className="wow fadeInUp"
                      data-wow-delay="0.3s"
                      data-wow-duration="1.3s"
                    >
                      {banner.description}
                    </p>
                    <Link
                      className="btn btn-link wow fadeInUp"
                      to="/shop"
                      data-wow-delay="0.3s"
                      data-wow-duration="1.3s"
                    >
                      Shop Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {/* product section start */}
      {bestProducts.length <= 3 ? (
        <></>
      ) : (
        <div
          className="product_section mb-80 wow fadeInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.1s"
        >
          <div className="container">
            <div className="section_title text-center mb-55">
              <h2>Best Seller</h2>
              <p>
                The Sweet Taste of Success: Selling Cakes, Chocolate, and
                Brownies
              </p>
            </div>
            <Slider
              {...settings}
              className="row product_slick slick_navigation slick__activation"
            >
              {bestProducts?.map((product) => {
                return (
                  <div className="col-lg-3" key={product._id}>
                    <article className="single_product">
                      <figure>
                        <div className="product_thumb">
                          <Link to={`/shop/${product._id}`}>
                            <img src={urlFor(product?.image)} alt="" />
                          </Link>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4>
                            <a href="#">{product.title}</a>
                          </h4>
                          <div className="price_box">
                            <span className="current_price">
                              Rs.{product.price}
                            </span>
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}

      {/* product section end */}
      {/* brand section start */}

      <Featured />

      {/* brand section end */}
      {/* blog section start */}
      <div className="blog_section mb-90">
        <div className="container">
          <div
            className="section_title text-center wow fadeInUp mb-50"
            data-wow-delay="0.1s"
            data-wow-duration="1.1s"
          >
            <h2>Latest Blog</h2>
            <p>
              Staying Ahead of the Curve: Insights and Trends from the Latest
              Blogs
            </p>
          </div>
          {blogs.length <= 3 ? (
            blogs?.map((blog) => {
              return <BlogCard blog={blog} key={blog._id} />;
            })
          ) : (
            <Slider
              {...blog}
              className="row blog_inner slick__activation home_blog"
            >
              {blogs?.map((blog) => {
                return <BlogCard blog={blog} key={blog._id} />;
              })}
            </Slider>
          )}
        </div>
      </div>
      {/*footer area start*/}
      <Footer />
    </>
  );
};

export default Home;
