import React, { useEffect, useState } from "react";
import { client, urlFor } from "../client";
import BodyOverlay from "../components/BodyOverlay";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Product from "../components/Product";

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const query =
      // "*[_type == 'product']{ ...,'category' : *[_type=='productCategory' && references(^._id)]{ title } }";
      `*[_type == "product"] {
      ...,
      "cat": category->title
    }`;

    client.fetch(query).then((data) => {
      setProducts(data);
      setFiltered(data);
    });
  }, []);

  useEffect(() => {
    const query = '*[_type == "productCategory"]';

    client.fetch(query).then((data) => {
      setCategory(data);
    });
  }, []);

  useEffect(() => {
    if (activeCategory == "all") {
      setFiltered(products);
      return;
    }
    const filtered = products.filter(
      (product) => product.cat === activeCategory
    );

    setFiltered(filtered);
  }, [activeCategory]);

  // console.log(products);

  // products?.map((product)=>{
  //   console.log(product.category.title);
  // })

  return (
    <>
      <BodyOverlay />
      <Header />
      {/* breadcrumbs area start */}
      <div
        className="breadcrumbs_aree breadcrumbs_bg mb-100"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Products</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumbs area end */}
      {/* product page section start */}
      <div className="product_page_section mb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex align-items-center px-2 py-3 flex-wrap justify-content-center">
              <button
                onClick={() => setActiveCategory("all")}
                className={
                  activeCategory == "all"
                    ? "d-none border-0 d-sm-inline-block shadow-sm mx-2 mb-2 btn-order-cart active"
                    : "d-none border-0 d-sm-inline-block shadow-sm mx-2 mb-2 btn-order-cart"
                }
              >
                All
              </button>
              {category?.map((catItem, key) => {
                return (
                  <button
                    onClick={() => setActiveCategory(catItem.title)}
                    key={key}
                    className={
                      activeCategory == catItem.title
                        ? "d-none border-0 d-sm-inline-block shadow-sm mb-2 mx-2 btn-order-cart active"
                        : "d-none border-0 d-sm-inline-block shadow-sm mb-2 mx-2 btn-order-cart"
                    }
                  >
                    {catItem.title}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="product_page_wrapper">
                {/*shop gallery start*/}
                <div className="product_page_gallery">
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="grid">
                      <div className="row grid__product">
                        {filtered?.map((product) => {
                          return (
                            <Product product={product} key={product._id} />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/*shop gallery end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product page section end */}
      <Footer />
    </>
  );
};

export default Shop;
