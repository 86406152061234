import React, { useEffect, useState } from "react";
import { client, urlFor } from "./../client";
import Slider from "react-slick";

const Featured = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const query = '*[_type == "featured"][0..5]';

    client.fetch(query).then((data) => {
      setBrands(data);
    });
  }, []);

  const featured = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: false,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 576, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      {!brands.length == 0 ? (
        <div
          className="brand_section_area mb-100 wow fadeInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.1s"
        >
          <div className="container w-100">
            <div className="row w-100 m-0">
              <div className="col-12 p-0">
                <Slider {...featured} className="brand_inner slick__activation">
                  {brands?.map((brand) => {
                    return (
                      <div className="single_brand" key={brand._id}>
                        <a className="primary" href="#">
                          <img src={urlFor(brand?.image)} alt="" />
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Featured;
