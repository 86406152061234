import "./App.css";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Shop from "./pages/Shop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blogs from "./pages/Blogs";
import CatProduct from "./pages/CatProduct";
import SingleProduct from "./pages/SingleProduct";
import SingleBlog from "./pages/SingleBlog";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id" element={<SingleBlog />} />
          <Route path="/shop/:id" element={<SingleProduct />} />
          <Route path="/catproduct/:cat" element={<CatProduct />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
