import React, { useEffect, useState } from "react";
import { client, urlFor } from "../client";
import BodyOverlay from "../components/BodyOverlay";
import Category from "../components/Category";
import Featured from "../components/Featured";
import Header from "../components/Header";
import Testimonials from "../components/Testimonials";
import Footer from "./../components/Footer";

const About = () => {
  const [teams, setTeams] = useState([]);
  const [about, setAbout] = useState([]);

  useEffect(() => {
    const query = '*[_type == "team"]';
    const aboutQuery = `*[_type == "about"][_id == '90b1936e-182b-4c0b-8c28-c10c6a0ae57b']`;

    client.fetch(query).then((data) => {
      setTeams(data);
    });

    client.fetch(aboutQuery).then((data) => {
      setAbout(data);
    });
  }, []);

  return (
    <>
      <BodyOverlay />

      <Header />
      {/* breadcrumbs area start */}
      <div className="breadcrumbs_aree breadcrumbs_bg mb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {about?.map((about) => {
        return (
          <React.Fragment key={about._id}>
            <div
              className="about_video-section wow fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1.1s"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="about_video_thumb">
                      <img
                        src={urlFor(about?.image)}
                        alt="buddy's delight"
                        className="about_bg_image"
                      />
                      <div className="video_paly_icon">
                        <a
                          className="video_popup"
                          href={about.youtubelink}
                          target="_blank"
                        >
                          <img
                            src="../assets/img/others/video-play.png"
                            alt="buddy delight intro video"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about_description_section mb-105">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 AboutDivide borderBottomIndicator">
                    <div
                      className="about_desc wow fadeInUp"
                      data-wow-delay="0.1s"
                      data-wow-duration="1.1s"
                    >
                      <h2>Our Vision</h2>
                      <p>{about.vision}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 AboutDivide borderBottomIndicator">
                    <div
                      className="about_desc wow fadeInUp"
                      data-wow-delay="0.1s"
                      data-wow-duration="1.1s"
                    >
                      <h2>Our Mission</h2>
                      <p>{about.mission}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 AboutDivide">
                    <div
                      className="about_desc wow fadeInUp"
                      data-wow-delay="0.1s"
                      data-wow-duration="1.1s"
                    >
                      <h2>Our Story</h2>
                      <p>{about.story}</p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}

      {/* brand section start */}
      <Featured />

      {/* team member section start */}
      {!teams.length == 0 ? (
        <div className="team_member_section mb-110">
          <div className="container">
            <div
              className="section_title text-center wow fadeInUp mb-50"
              data-wow-delay="0.1s"
              data-wow-duration="1.1s"
            >
              <h2>Team Member</h2>
              <p>Fostering Unity and Cooperation Within Teams</p>
            </div>
            <div className="team_member_inner">
              <div className="row">
                {teams?.map((team) => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-6" key={team._id}>
                      <div
                        className="single_team_member wow fadeInUp"
                        data-wow-delay="0.1s"
                        data-wow-duration="1.1s"
                      >
                        <div className="team_thumb">
                          <a href="#">
                            <img src={urlFor(team?.image)} alt={team.name} />
                          </a>
                          <div className="team_text">
                            <h3>{team.name}</h3>
                            <h4>{team.position}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* testimonial section start */}
      <Testimonials />
      {/* service section start*/}
      <Category />
      {/* service section end*/}
      <Footer />
    </>
  );
};

export default About;
