import { useState } from "react";
import ReactLoading from "react-loading";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  return (
    <>
      <div className="newsletter_subscribe">
        <form>
          <input
            id="mc-email"
            type="email"
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            placeholder="Email Address"
            required
          />
          <button type="button" onClick={handleFormSubmit}>
            <i className="ion-arrow-right-c" />
          </button>
        </form>
      </div>
      <div className="min-h-42px">
        {"sending" === status ? (
          <div className="d-flex align-items-center">
            <ReactLoading
              type="spin"
              color="#ffffff"
              height={"20%"}
              width={"20%"}
            />
            &nbsp;&nbsp;
            <h2 className="text-white">Loading</h2>
          </div>
        ) : null}
        {"error" === status || error ? (
          <p
            className="mailchimp-error"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <p className="mailchimp-success active">Thank you for subscribing!</p>
        )}
      </div>
    </>
  );
};

export default NewsletterForm;
