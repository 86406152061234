import React, { useEffect, useState } from "react";
import { client } from "../client";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { useMediaQuery } from "@material-ui/core";

const Footer = () => {
  const [footer, setFooter] = useState([]);

  const matches = useMediaQuery("(max-width:574px)");

  useEffect(() => {
    const query = `*[_type == "settings"][_id == '2b1e55d8-cfd2-499f-899f-cc14fa7eacc7']`;

    client.fetch(query).then((data) => {
      setFooter(data);
    });
  }, []);

  return (
    <>
      <footer className="footer_widgets">
        <div className="container">
          <div className="main_footer">
            <div className="row">
              <div className="col-12">
                {footer?.map((footer) => {
                  return (
                    <div className="main_footer_inner d-flex" key={footer._id}>
                      <div className="footer_widget_list contact footer_list_width">
                        <h3>Contact Us</h3>
                        {/* <div className="footer_contact_desc">
                          <p>
                            If you have any question.please contact us at&nbsp;
                            <a href={`mailto:${footer.email}`}>
                              {footer.email}
                            </a>
                          </p>
                        </div> */}
                        <div className="footer_contact_info">
                          <div className="footer_contact_info_list d-flex align-items-center">
                            <div className="footer_contact_info_icon">
                              <span className="pe-7s-map-marker" />
                            </div>
                            <div className="footer_contact_info_text">
                              <p>{footer.address}</p>
                            </div>
                          </div>
                          <div className="footer_contact_info_list d-flex align-items-center">
                            <div className="footer_contact_info_icon">
                              <span className="pe-7s-phone" />
                            </div>
                            <div className="footer_contact_info_text">
                              <ul>
                                <li>
                                  <a href="tel:+0123456789">
                                    + {footer.phone1}
                                  </a>
                                </li>
                                {!footer.phone2 ? (
                                  <></>
                                ) : (
                                  <li>
                                    <a href="tel:+0123456789">
                                      + {footer.phone2}
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="footer_menu_widget footer_list_width middle d-flex">
                        <div className="footer_widget_list">
                          <h3>Information</h3>
                          <div className="footer_menu">
                            <ul>
                              <li>
                                <a href="/about"> About us</a>
                              </li>
                              <li>
                                <a href="/contact">Delivery information</a>
                              </li>
                              <li>
                                <a href="/shop">Products</a>
                              </li>
                              <li>
                                <a href="/blog">Blogs</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="footer_widget_list">
                          <h3>Social Media</h3>
                          <div className="footer_menu">
                            <ul
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <li>
                                <a href={footer.instagram} target="_blank">
                                  <i className="bx bxl-instagram contactIcon"></i>
                                </a>
                              </li>
                              <li>
                                <a href={footer.facebook} target="_blank">
                                  <i className="bx bxl-facebook-circle contactIcon"></i>
                                </a>
                              </li>
                              <li>
                                <a href={footer.youtube} target="_blank">
                                  <i className="bx bxl-youtube contactIcon"></i>
                                </a>
                              </li>
                              <li>
                                <a href={footer.tiktok} target="_blank">
                                  <i className="bx bxl-tiktok contactIcon"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="footer_widget_list footer_list_width">
                        <h3>newsletter</h3>
                        <div className="footer_newsletter">
                          <div className="newsletter_desc">
                            <p>
                              Subscribe &nbsp;
                              <a href={`mailto:${footer.email}`}>
                                Send Us an Email
                              </a>
                            </p>
                          </div>
                          {/*Mailchimp Newsletter Subscription*/}
                          <NewsletterSubscribe />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="footer_bottom">
            <div className="copyright_right text-center">
              <p>
                © 2023 <a href=""> (Buddys delight).</a> {matches ? <br /> : ""}
                Made with&nbsp;
                <i className="ion-heart" /> by
                <a href="https://inintechnologies.com" target="_blank">
                  &nbsp;ININ Technologies
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
