import React from "react";
import { Link } from "react-router-dom";
import { urlFor } from "../client";

const Product = ({ product }) => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <article className="single_product">
          <figure>
            <div className="product_thumb">
              <Link to={`/shop/${product._id}`}>
                <img src={urlFor(product?.image)} alt={product.title} />
              </Link>
            </div>
            <figcaption className="product_content text-center">
              <h4>
                <a href="#">{product.title}</a>
              </h4>
              {/* <p>{product.category}</p> */}
              <div className="price_box">
                <span className="current_price">
                  {product.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                  })}
                </span>
              </div>
            </figcaption>
          </figure>
        </article>
      </div>
    </>
  );
};

export default Product;
