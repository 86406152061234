import React, { useEffect, useState } from "react";
import { client, urlFor } from "../client";
import BodyOverlay from "../components/BodyOverlay";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import BlockContent from "@sanity/block-content-to-react";

const SingleBlog = () => {
  const location = useLocation();
  const slug = location.pathname.split("/")[2];

  const [blog, setBlog] = useState([]);
  const [relatedBlog, setRelatedBlog] = useState([]);

  useEffect(() => {
    const query = `*[_type == "post"]{
      ...,
      "name": author->name,
      "authorImg" : author->image,
      "cat": categories->title
    }[_id == '${slug}']`;

    client.fetch(query).then((data) => {
      setBlog(data);
    });
  }, [blog]);

  useEffect(() => {
    const blogsquery = `*[_type == "post"]{
      ...,
      "name": author->name,
      "authorImg" : author->image,
      "cat": categories->title
    }`;

    client.fetch(blogsquery).then((data) => {
      setRelatedBlog(data);
    });
  }, [relatedBlog]);

  // console.log(blog);

  return (
    <>
      <BodyOverlay />
      <Header />
      {/* breadcrumbs area start */}
      <div className="breadcrumbs_aree breadcrumbs_bg mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Blog</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumbs area end */}
      {/*blog body area start*/}
      <div className="blog_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 order-2 order-lg-1">
              <div className="blog_sidebar blog_widget">
                <div className="blog_widget_list">
                  <h3>Recent Post</h3>
                  <div className="recent_post_inner">
                    {relatedBlog?.map((blog) => {
                      return (
                        <div className="recent_post_list d-flex">
                          <div className="recent_post_thumb">
                            <Link to={"/blog/" + blog._id}>
                              <img
                                src={urlFor(blog?.mainImage)}
                                alt={blog.title}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                              />
                            </Link>
                          </div>
                          <div className="recent_post_content">
                            <span>
                              <i className="icofont-calendar" />
                              {moment(blog.publishedAt)
                                .utc()
                                .format("YYYY-MM-DD")}
                            </span>
                            <h4>
                              <Link to={"/blog/" + blog._id}>{blog.title}</Link>
                            </h4>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-1 order-lg-2">
              {blog?.map((blog) => {
                return (
                  <div className="blog_details_left">
                    <div className="blog_sticky_thumb">
                      <img
                        src={urlFor(blog?.mainImage)}
                        alt={blog.title}
                        style={{ width: "100%", borderRadius: "20px" }}
                      />
                    </div>
                    <div className="blog_details_content ">
                      <span className="brakery">{blog.cat}</span>
                      <div className="blog_details_title">
                        <h2>{blog.title}</h2>
                      </div>
                      <div className="blog__meta d-flex align-items-center">
                        <div className="blog__meta__thumb">
                          <img
                            src={blog.authorImg ? urlFor(blog?.authorImg) : "../assets/img/others/no-user.png"}
                            alt={blog.name}
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div className="blog__meta__text">
                          <ul className="d-flex">
                            <li>{blog.name}</li>
                            <li>
                              <i className="icofont-calendar" />
                              {moment(blog.publishedAt)
                                .utc()
                                .format("YYYY-MM-DD")}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="blog_details_desc">
                        <BlockContent
                          blocks={blog.body}
                          projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                          dataset="production"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*blog section area end*/}
      <Footer />
    </>
  );
};

export default SingleBlog;
