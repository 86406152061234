import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const navbarToggler = () => {
    setIsActive((current) => !current);
  };
  return (
    <>
      <div className="offcanvas_menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className={
                  isActive
                    ? "offcanvas_menu_wrapper active"
                    : "offcanvas_menu_wrapper"
                }
              >
                <div className="offcanvas_header header_logo">
                  <Link className="sticky_none" to="/">
                    <img src="../assets/img/logo/logo.png" alt="buddy's delight" />
                  </Link>
                </div>

                <div className="canvas_close">
                  <a href="#" onClick={navbarToggler}>
                    <i className="ion-android-close" />
                  </a>
                </div>
                
                <div className="welcome_text text-center">
                  <p>Welcome to Buddys Delight</p>
                </div>
                
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/about">About</Link>
                    </li>

                    <li className="menu-item-has-children">
                      <Link to="/shop">Products</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/blog">blog</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main_header d-flex justify-content-between align-items-center">
                <div className="header_logo">
                  <Link className="sticky_none" to="/">
                    <img src="../assets/img/logo/logo.png" alt="buddy's delight" />
                  </Link>
                </div>
                {/*main menu start*/}
                <div className="main_menu d-none d-lg-block">
                  <nav>
                    <ul className="d-flex">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>

                      <li>
                        <Link to="/shop">Products</Link>
                      </li>
                      <li>
                        <Link to="/blog">blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/*main menu end*/}
                <div className="header_account">
                  <div className="canvas_open">
                    <a href="#" onClick={navbarToggler}>
                      <i className="ion-navicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
