import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "./../components/Footer";
import { client, urlFor } from "../client";
import { Link, useLocation } from "react-router-dom";
import BodyOverlay from "../components/BodyOverlay";
import Product from "../components/Product";

const CatProduct = () => {
  const location = useLocation();
  const category = location.pathname.split("/")[2];
  const categoryReplace = category.replace(/%20/g, " ");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const query = `*[_type == "product" && category._ref in *[_type=="productCategory" && title=="${categoryReplace}"]._id ]{
      ...
    }`;

    client.fetch(query).then((data) => {
      setProducts(data);
    });
  }, []);

  // console.log(categoryReplace);

  return (
    <>
      <BodyOverlay />
      <Header />
      {/* breadcrumbs area start */}
      <div
        className="breadcrumbs_aree breadcrumbs_bg mb-110"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Products</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product section start */}
      <div
        className="product_section mb-80 wow fadeInUp"
        data-wow-delay="0.1s"
        data-wow-duration="1.1s"
      >
        <div className="container">
          <div className="product_header">
            <div className="section_title text-center">
            <h4>{categoryReplace}</h4>
              <h2>New Products</h2>
            </div>
          </div>
          <div className="tab-content product_container">
            <div
              className="tab-pane fade show active"
              id="features"
              role="tabpanel"
            >
              <div className="product_gallery">
                <div className="row">
                  {products?.map((product) => {
                    return (
                   
                      <Product product={product} key={product._id} />
                    );
                  })}
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* product section end */}

      {/*footer area start*/}
      <Footer />
      {/*footer area end*/}
    </>
  );
};

export default CatProduct;
