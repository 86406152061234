import React, { useEffect, useState } from "react";
import { client, urlFor } from "../client";
import Slider from "react-slick";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const query = '*[_type == "testimonial"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div
        className="testimonial_section mb-110 wow fadeInUp"
        data-wow-delay="0.1s"
        data-wow-duration="1.1s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider
                {...settings}
                className="testimonial_wrapper slick__activation"
              >
                {testimonials?.map((testimonial) => {
                  return (
                    <div
                      className="testimonial_inner d-flex align-items-center"
                      key={testimonial._id}
                    >
                      <div className="testimonial_thumb">
                        <img
                          src={urlFor(testimonial?.image)}
                          alt={testimonial.name}
                        />
                      </div>
                      <div className="testimonial_content">
                        
                        <div className="testimonial_author">
                          <h3>{testimonial.name}</h3>
                          <h4>{testimonial.company}</h4>
                        </div>
                        <div className="testimonial_desc">
                          <p>{testimonial.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
