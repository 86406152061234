import React, { useEffect, useRef, useState } from "react";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BodyOverlay from "../components/BodyOverlay";
import { client } from "../client";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [contact, setContact] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  useEffect(() => {
    const query = `*[_type == "settings"][_id == '2b1e55d8-cfd2-499f-899f-cc14fa7eacc7']`;

    client.fetch(query).then((data) => {
      setContact(data);
    });
  }, []);

  const sendEmail = (e) => {
    setLoading(true);

    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          setLoading(false);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <BodyOverlay />
      <Header />
      {/* breadcrumbs area start */}
      <div className="breadcrumbs_aree breadcrumbs_bg mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs_text">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact section start */}
      <div className="contact_page_section mb-100">
        <div className="container">
          <div className="contact_details">
            <div className="row">
              <div className="col-lg-7 col-md-6">
                {contact?.map((contact) => {
                  return (
                    <div className="contact_info_content" key={contact._id}>
                      <h2>We Are Here To Help You! Please Contact Us.</h2>

                      <div className="contact_info_details mb-45">
                        <h3>Store In Colombo</h3>
                        <p className="contactDetails">
                          <a href={contact.googleMap} target="_blank">
                            <i className='bx bx-map contactIcon'></i>
                            {contact.address}
                          </a>
                        </p>
                        <p className="contactDetails">
                          <a href={`tel:${contact.phone1}`}>
                            <i class='bx bx-phone-call contactIcon' ></i>
                            +{contact.phone1}
                          </a>
                        </p>
                        <p className="contactDetails">
                          <a href={`mailto:${contact.email}`}>
                            <i className='bx bx-envelope contactIcon' ></i>
                            {contact.email}
                          </a>
                        </p>
                        <p className="contactDetails">
                          <a href={contact.instagram} target="_blank">
                            <i className='bx bxl-instagram contactIcon' ></i>
                            Instagram
                          </a>
                        </p>
                        <p className="contactDetails">
                          <a href={contact.facebook} target="_blank">
                            <i className='bx bxl-facebook-circle contactIcon' ></i>
                            Facebook
                          </a>
                        </p> 
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="contact_form">
                  <h2>Send A Quest</h2>
                  <form id="contact-form" ref={form} onSubmit={sendEmail}>
                    <div className="form_input">
                      <input
                        name="name"
                        placeholder="Name*"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form_input">
                      <input
                        name="email"
                        placeholder="E-Mail*"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form_input">
                      <input
                        name="subject"
                        placeholder="Subject"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form_textarea">
                      <textarea
                        name="message"
                        placeholder="Message Hare"
                        defaultValue={""}
                        required
                      />
                    </div>
                    <div className="form_input_btn">
                      <button type="submit" className="btn btn-link">
                        {!loading ? "Send Message" : "Sending..."}
                      </button>
                    </div>
                    {isFormSubmitted ? (
                      <p className="form-message success">
                        Thank You! Your message has been sent.
                      </p>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*contact map start*/}
      <div className="contact_map mt-70">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.47546251524!2d79.8638048!3d6.8334547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25bd335200e7b%3A0xbd732d7a276b07c0!2sBuddy&#39;s%20Delight!5e0!3m2!1sen!2slk!4v1673349594271!5m2!1sen!2slk"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/*contact map end*/}
      <Footer />
    </>
  );
};

export default Contact;
